import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { inject, observer } from 'mobx-react'
import { PaginatedSearchableParams } from '../../../lib/types/Params'
import { CCol, CFormLabel, CRow } from '@coreui/react'
import {
  isDirtySelectableItem,
  RichTextEditor,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
} from '@mobilizeyourtech/vision-core-react'
import React from 'react'
import { GovUserExtendedDataFormData } from './types'
import { TechnologyTypeResponse } from '../../../stores/ServerStore'

export interface GovUserExtendedDataProfileFormProps<T extends GovUserExtendedDataFormData> {
  value: Partial<T>
  onChange: (value: Partial<T>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}

export const GovUserExtendedDataProfileForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer(
    <T extends GovUserExtendedDataFormData>(props: GovUserExtendedDataProfileFormProps<T>) => {
      const onChange = (change: Partial<GovUserExtendedDataFormData>) =>
        props.onChange({ ...props.value, ...change })

      // TECH TYPES DROPDOWN START //////
      const getTechnologyTypes = (params: PaginatedSearchableParams) => {
        return props.serverStore!.getTechnologyTypes(params).then((response) => ({
          pagination: response.pagination,
          data: response.data.map((r) => {
            return { ...r, label: r.name }
          }),
        }))
      }
      const getSelectedTechnologyTypes = () => {
        if (props.value.technologyTypes === undefined) {
          return []
        }

        return props.value.technologyTypes?.map((t) => {
          return { ...t, label: t.name }
        })
      }
      // TECH TYPES DROPDOWN END ///////

      const technologyTypesRow = () => {
        return (
          <CRow className="mb-3">
            <CFormLabel className="col-sm-2 col-form-label">Technology Types</CFormLabel>
            <i className="fas fa-asterisk gov-required-icon tech-types" />
            <CCol>
              <ServerBackedMultiSelectAutoComplete
                required
                loadMore={getTechnologyTypes}
                onChange={(selected) => {
                  onChange({
                    technologyTypes: selected.filter((e) => !isDirtySelectableItem(e)) as Array<
                      TechnologyTypeResponse & SelectableItem
                    >,
                  })
                }}
                selections={getSelectedTechnologyTypes()}
                placeholder="Select Technology Types..."
                className="technology-type-search-dropdown ValidatedInput"
                data-testid="technology-type-search-dropdown"
              />
            </CCol>
          </CRow>
        )
      }

      const profileDetailsRow = () => {
        return (
          <CRow className="mb-3">
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
              <CFormLabel className="w-100 col-sm-2 col-form-label">
                About you <i className="fas fa-asterisk gov-required-icon" />
                (required)
              </CFormLabel>
              <span className={'subtle'}>
                Role description, problems you’re solving, and anything else you want people to
                know.
              </span>
              <CCol>
                <RichTextEditor
                  className="profile-details-editor"
                  data-testid="profile-details-editor"
                  value={props.value.profileDetail}
                  onChange={(value) => {
                    onChange({ profileDetail: value })
                  }}
                />
              </CCol>
            </CCol>
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-5 exampleDiv'}>
              <div>Example:</div>
              <p>
                Hi! I am a program manager working specifically in advanced manufacturing. I’m
                especially interested in innovative 3D printing opportunities.
              </p>
              <br />
              <p>
                Currently, we’re trying to source materials for a 3D printing project that is
                focused on Tactical Combat Casualty Care (TCCC).
              </p>
            </CCol>
          </CRow>
        )
      }

      const interactionDetailRow = () => {
        return (
          <CRow className="mb-3">
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
              <CRow>
                <CFormLabel className="w-100 col-sm-2 col-form-label">
                  How can you work with small businesses?{' '}
                  <i className="fas fa-asterisk gov-required-icon" />
                  (required)
                </CFormLabel>
                <CCol>
                  <RichTextEditor
                    className="interaction-details-editor"
                    data-testid="interaction-details-editor"
                    value={props.value.interactionDetail || ''}
                    onChange={(value) => {
                      onChange({ interactionDetail: value })
                    }}
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-4 exampleDiv'}>
              <div>Example:</div>
              <p>TPOC, MOU, Phase 1 stakeholder engagement, etc.</p>
            </CCol>
          </CRow>
        )
      }

      return (
        <div className={'GovUserExtendedDataProfileForm'}>
          {technologyTypesRow()}
          {profileDetailsRow()}
          {interactionDetailRow()}
        </div>
      )
    },
  ),
)
