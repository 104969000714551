import { inject, observer } from 'mobx-react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CBadge,
  CButton,
  CCardHeader,
  CCardText,
  CTooltip,
} from '@coreui/react'

import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { GovUserData } from '../../stores/UserStore'
import { leftArrow } from '../../components/icons/leftArrow'
import { StartChatButton } from '../../components/custom'
import ReactHtmlParser from 'react-html-parser'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'
import { UserAvatar } from '../../components/user'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}

interface MatchParams {
  id: string
}

export const ViewIndividualGovUser = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()
    const [govUser, setGovUser] = useState<GovUserData | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const navigate = useHistory()
    const goBack = () => {
      navigate.goBack()
    }

    useEffect(() => {
      setIsLoading(true)
      props.userStore
        ?.retrieveGovUserById(id, 'technologyTypes,govUserSetting,isActive')
        .then((data) => setGovUser(data))
        .catch((err) => {
          setError(err)
          props.notificationStore?.setNotificationMessage(
            'Error retrieving gov user. Please try again.',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }, [])

    const renderUser = (user: GovUserData) => (
      <CContainer className="IndividualGovUser">
        <div className={'py-4 d-flex justify-content-between'}>
          <div className="gov-user-header d-flex flex-row justify-content-start align-items-center gap-3">
            <CButton data-testid="back-btn" className="go-back" variant="ghost" onClick={goBack}>
              {leftArrow()}
            </CButton>
            <div className="gov-user-avatar d-flex justify-content-center align-items-center">
              <UserAvatar user={user} displayIsActive />
            </div>
            <div className="gov-user-title d-flex justify-content-center align-items-center">
              <h4>
                {user.firstName} {user.lastName}{' '}
              </h4>
              {user.govUserExtendedData.visionUserId && (
                <CTooltip content={'Verified VISION user'}>
                  <div className="d-flex justify-content-center align-items-center ms-2 mb-1 icon-container">
                    {visionVisibilityIcon()}
                  </div>
                </CTooltip>
              )}
            </div>
          </div>
          <div>{govUser && <StartChatButton target={govUser} />}</div>
        </div>
        <CRow className="gov-user-card-container justify-content-center flex-wrap gap-3 mt-3 mb-3">
          <CCol className="gov-user-column">
            <CCard className="gov-user info-card py-3 px-4">
              <CCardHeader>
                <CCardTitle className="ms-0">User Info</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <div className="info d-flex justify-content-between pt-4">
                  <div className="category w-25">
                    <p>Name</p>
                  </div>
                  <div className="text d-flex justify-content-start w-75 gap-2">
                    <p>{user.firstName + ' ' + user.lastName}</p>
                  </div>
                </div>
                <div className="info d-flex justify-content-between align-items-center">
                  <div className="category w-25">
                    <p>Title/Rank</p>
                  </div>
                  <div className="text d-flex justify-content-start w-75 gap-2">
                    <p>{user.title}</p>
                  </div>
                </div>
                <div className="info d-flex justify-content-between align-items-center">
                  <div className="category w-25">
                    <p>Organization</p>
                  </div>
                  <div className="text d-flex justify-content-start w-75 gap-2">
                    <p>
                      {user.govUserExtendedData.govUserOrganization.shortName +
                        ' - ' +
                        user.govUserExtendedData.govUserOrganization.longName}
                    </p>
                  </div>
                </div>
                <div className="info d-flex justify-content-between align-items-center">
                  <div className="category w-25">
                    <p>Component / Detailed Organization</p>
                  </div>
                  <div className="text d-flex justify-content-start w-75 gap-2">
                    <p>{user.govUserExtendedData.govUserProgramOffice?.label}</p>
                  </div>
                </div>
                <div className="contact d-flex flex-column pt-4">
                  <div className="info d-flex justify-content-between align-items-center">
                    <div className="category w-25">
                      <i className="fa fa-clock" />
                    </div>
                    <div className="text d-flex justify-content-start w-75 gap-2">
                      <p>{user.timezone.split('_').join(' ')}</p>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol className="gov-user-column">
            <CCard className="gov-user tech-types-card py-3 px-4 h-100">
              <CCardHeader>
                <CCardTitle className="p-0 ms-0">Technology Types</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CRow className="justify-content-md-start pt-2">
                  {user.technologyTypes.map((tt) => {
                    return (
                      <CCol key={tt.name} className={'col-md-auto px-1 mb-2'}>
                        <CBadge color="primary" className="text-wrap text-md-start py-2">
                          {tt.name}
                        </CBadge>
                      </CCol>
                    )
                  })}
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={12} md={12} sm={12}>
            <CCard className="gov-user tech-types-card py-3 px-4">
              <CCardHeader>
                <CCardTitle className="p-0 ms-0">Problem Statement & Role Description</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <div className={'ql-editor'}>
                  {user.govUserExtendedData.profileDetail &&
                    ReactHtmlParser(user.govUserExtendedData.profileDetail)}
                </div>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={12} md={12} sm={12}>
            <CCard className="gov-user tech-types-card py-3 px-4">
              <CCardHeader>
                <CCardTitle className="p-0 ms-0">
                  Desired Interactions With Small Businesses
                </CCardTitle>
              </CCardHeader>
              <CCardBody>
                <div className={'ql-editor'}>
                  {user.govUserExtendedData.interactionDetail &&
                    ReactHtmlParser(user.govUserExtendedData.interactionDetail)}
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    )

    const renderError = () => (
      <CContainer
        data-testid="error-container"
        className="error-container w-100 d-flex align-items-center justify-content-center"
      >
        <CCard className="gov-user error justify-content-center align-items-center">
          <CCardBody className="d-flex justify-content-center align-items-center">
            <CCardText>Error retrieving gov user. Please try again.</CCardText>
          </CCardBody>
        </CCard>
      </CContainer>
    )

    return (
      <section className="ViewIndividualGovUser">
        <SuspensionBoundary isLoading={isLoading}>
          {!error && govUser ? renderUser(govUser) : renderError()}
        </SuspensionBoundary>
      </section>
    )
  }),
)
