import React from 'react'
import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react'
import { SingleImageUploader, TimezoneSelect, ValidatedCFormInput } from '../../custom'
import { validateLength } from '../../../lib/helpers/validation'
import { ITimezone } from '../../../lib/time'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { SocialMediaAccountsForm, SocialMediaFormData } from './SocialMediaAccountsForm'

export type SignupUserData = {
  firstName: string
  lastName: string
  title: string
  cellPhone: string
  workPhone?: string
  workPhoneExt?: string
  timezone: ITimezone
  socialMedia?: Array<SocialMediaFormData>
  headshot: File | string | undefined
}

type TProps = {
  value: Partial<SignupUserData>
  onChange: (value: Partial<SignupUserData>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  isGovUser?: boolean
}
export const SignupUserForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const onChange = (change: Partial<SignupUserData>) =>
      props.onChange({ ...props.value, ...change })

    const defaultCharLengthMessage = 'Must be a minimum of 2 characters'

    const firstNameRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserFirstName' }} className="col-sm-2 col-form-label">
            First Name
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="signupUserFirstName"
              data-testid="signupUserFirstName"
              placeholder="First Name"
              value={props.value.firstName || ''}
              onChange={(value) => {
                onChange({ firstName: value })
              }}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
      )
    }
    const lastNameRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserLastName' }} className="col-sm-2 col-form-label">
            Last Name
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="signupUserLastName"
              data-testid="signupUserLastName"
              placeholder="Last Name"
              value={props.value.lastName || ''}
              onChange={(value) => onChange({ lastName: value })}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
      )
    }
    const headShotRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel className="col-sm-2 col-form-label">Headshot</CFormLabel>
          <CCol>
            <SingleImageUploader
              data-testid={'userHeadshotUploader'}
              onFile={(file) => onChange({ headshot: file })}
              value={props.value.headshot}
            />
          </CCol>
        </CRow>
      )
    }
    const titleRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserTitle' }} className="col-sm-2 col-form-label">
            {props.isGovUser ? 'Title/Rank' : 'Title'}
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="signupUserTitle"
              data-testid="signupUserTitle"
              placeholder="Title"
              value={props.value.title || ''}
              onChange={(value) => onChange({ title: value })}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
      )
    }
    const timeZoneRow = () => {
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserTimezone' }} className="col-sm-2 col-form-label">
            Timezone
          </CFormLabel>
          <CCol>
            <TimezoneSelect
              id="signupUserTimezone"
              data-testid="testSignupUserTimezone"
              required
              value={props.value.timezone}
              onSelect={(v) => {
                onChange({ timezone: v })
              }}
            />
          </CCol>
        </CRow>
      )
    }
    const contactInfoInputs = () => {
      return (
        <>
          <CRow className="mb-3" data-testid={'igniteUserFormView'}>
            <CFormLabel {...{ htmlFor: 'signupUserCellPhone' }} className="col-sm-2 col-form-label">
              Mobile Phone
            </CFormLabel>
            <CCol>
              <ValidatedCFormInput
                type="text"
                id="signupUserCellPhone"
                data-testid="signupUserCellPhone"
                placeholder="Mobile Phone"
                value={props.value.cellPhone || ''}
                onChange={(value) => onChange({ cellPhone: value })}
                required
                /* TODO valid phone */
                validate={(v) => validateLength(v, 2)}
                renderInvalidMessage={() => defaultCharLengthMessage}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel {...{ htmlFor: 'signupUserWorkPhone' }} className="col-sm-2 col-form-label">
              Work Phone
            </CFormLabel>
            <CCol>
              <CFormInput
                type="text"
                id="signupUserWorkPhone"
                data-testid="signupUserWorkPhone"
                placeholder="Work Phone"
                value={props.value.workPhone || ''}
                onChange={({ target: { value } }) => onChange({ workPhone: value })}
              />
            </CCol>
            <CFormLabel
              {...{ htmlFor: 'signupUserWorkPhoneExt' }}
              className="col-sm-2 col-form-label"
            >
              Work Extension
            </CFormLabel>
            <CCol sm={3}>
              <CFormInput
                type="text"
                id="signupUserWorkPhoneExt"
                data-testid="signupUserWorkPhoneExt"
                placeholder="Work Extension"
                value={props.value.workPhoneExt || ''}
                onChange={({ target: { value } }) => onChange({ workPhoneExt: value })}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <SocialMediaAccountsForm
              value={props.value.socialMedia || []}
              onChange={(change) => onChange({ socialMedia: change })}
            />
          </CRow>
        </>
      )
    }

    return (
      <>
        {firstNameRow()}
        {lastNameRow()}
        {headShotRow()}

        {titleRow()}
        {timeZoneRow()}
        {!props.isGovUser && contactInfoInputs()}
      </>
    )
  }),
)
