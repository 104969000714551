import React, { useState } from 'react'
import { CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import {
  SignupLoginData,
  SignupLoginForm,
  SignupUserData,
  SignupUserForm,
} from '../components/form/fragments'
import { validateLength, validatePassword, validateValueMatch } from '../lib/helpers/validation'
import ReCAPTCHA from 'react-google-recaptcha'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { SuspensefulButton } from '@mobilizeyourtech/vision-core-react'
import { Link } from 'react-router-dom'
import { leftArrow } from '../components/icons/leftArrow'

type InviteUserFormData = SignupLoginData & SignupUserData

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  applicationStore?: ApplicationStore
}
export const InviteSignUp = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  'applicationStore',
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      inviteToken: withDefault(StringParam, undefined),
      firstName: withDefault(StringParam, undefined),
      lastName: withDefault(StringParam, undefined),
    })
    const [invitedUserData, setInvitedUserData] = useState<Partial<InviteUserFormData>>({
      firstName: params.firstName,
      lastName: params.lastName,
    })
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let errors = [
        validatePassword(invitedUserData.password),
        validateValueMatch(invitedUserData.password, invitedUserData.confirmPassword),
        validateLength(invitedUserData.firstName, 2),
        validateLength(invitedUserData.lastName, 2),
        validateLength(invitedUserData.title, 2),
        validateLength(invitedUserData.cellPhone, 2),
      ]

      if (errors.some((err) => !!err)) {
        return false
      }

      let presence = [invitedUserData.timezone, recaptchaToken].every((e) => !!e)

      return presence
    }

    const onSubmit = () => {
      if (!isSubmittable()) {
        return
      }

      setHasValidated(true)
      setIsSubmitting(true)

      props
        .userStore!.inviteSignUp({
          user: {
            password: invitedUserData.password!,
            confirmPassword: invitedUserData.confirmPassword!,
            firstName: invitedUserData.firstName!,
            lastName: invitedUserData.lastName!,
            title: invitedUserData.title!,
            cellPhone: invitedUserData.cellPhone!,
            workPhone: invitedUserData.workPhone,
            workPhoneExt: invitedUserData.workPhoneExt,
            timezone:
              typeof invitedUserData.timezone === 'string'
                ? invitedUserData.timezone
                : invitedUserData.timezone!.value,
            headshot: invitedUserData.headshot,
            socialMedia: invitedUserData.socialMedia,
          },
          recaptchaToken: recaptchaToken!,
          inviteToken: params.inviteToken!,
        })
        .then(({ authorization, ...userdata }) => {
          setIsSubmitting(false)

          props.notificationStore?.setNotificationMessage(
            'Account successfully created!',
            'success',
            3000,
            true,
          )
          props.authStore!.setJWT(authorization)
          props.authStore!.generateAndSetSalt()
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Something went wrong, please try again',
            'danger',
            3000,
          )
        })
    }
    return (
      <section className="InviteSignUp">
        <CForm validated={hasValidated} className="needs-validation" {...{ noValidate: true }}>
          <CContainer>
            <CCard>
              <CCardBody>
                <div className="bounded-container d-flex align-items-center">
                  <Link
                    className="nav-back clickable"
                    data-testid="nav-back"
                    aria-label="navigate back to signin"
                    tabIndex={0}
                    to="/"
                  >
                    {leftArrow()}
                  </Link>
                  <h5>Invite Sign Up</h5>
                </div>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your Login</b>
                      </h6>
                      <SignupLoginForm
                        value={invitedUserData}
                        onChange={(value) => setInvitedUserData(value)}
                        isEmailHidden={true}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your User</b>
                      </h6>
                      <SignupUserForm
                        value={invitedUserData}
                        onChange={(value) => setInvitedUserData(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CContainer className="recaptcha-container">
                  <ReCAPTCHA
                    sitekey={props.applicationStore!.siteSettings!['recaptchaSiteKey']}
                    onChange={(token) => setRecaptchaToken(token)}
                    data-testid="recaptcha-checkbox"
                  />
                </CContainer>
                <CRow className="justify-content-md-center">
                  <CCol sm={2} className="d-flex justify-content-md-center">
                    <SuspensefulButton
                      isSuspended={isSubmitting}
                      onInvoke={onSubmit}
                      buttonText="Sign Up"
                      disabled={!isSubmittable() || isSubmitting}
                      data-testid="signup-button"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </CForm>
      </section>
    )
  }),
)
