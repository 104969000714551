import { AuthStore, NotificationStore, ServerStore } from './'
import { action, makeObservable, observable } from 'mobx'
import { FileResponseData } from '../lib/types'
import { SignupUserData, SignupLoginData } from '../components/form/fragments'
import { validateLength, validatePassword, validateValueMatch } from '../lib/helpers/validation'
import { SelectableItem, ServerPageData } from '@mobilizeyourtech/vision-core-react'
import {
  GovUserOrganizationResponse,
  GovUserProgramOfficeResponse,
  TechnologyTypeResponse,
} from './ServerStore'
import {
  CountResponse,
  GovUserPaginatedSearchableParams,
  PaginationParams,
  SortableParams,
} from '../lib/types/Params'
import * as Cable from '@rails/actioncable'
import { Consumer } from '@rails/actioncable'
import { getFullSessionUrl } from '../lib/helpers/sessionHelpers'

export type SocialMediaAccount = {
  socialMediaTypeName: string
  socialMediaTypeId: number
  identifier: string
  id: number
}

export interface SocialMediaUserFormData {
  socialMediaTypeId: number | string
  identifier: string
}

export interface ClaimRequestData {
  id: number
  firstName: string
  lastName: string
  email: string
  accountId: number
}

export interface DecryptedGovUserData {
  firstName?: string
  lastName?: string
  email: string
  visionPreferredUsername: string
  position?: string
  visionUserId: number
}

export interface GenericSearchMetadata {
  rank: number
  highlight: string
}

export type ConfirmationEmailBody = {
  user?: { email: string }
  redirectTo?: string
}

export interface UserData {
  id: number
  accountId: number
  firstName: string
  lastName: string
  email: string
  title: string
  timezone: string
  cellPhone: string
  workPhone?: string
  workPhoneExt?: string
  headshot: FileResponseData | null
  socialMediaAccounts: Array<SocialMediaAccount>
  listings: string
  isAdmin: boolean
  confirmed: boolean
  unconfirmedEmail?: string
  userRoles: Array<string>
  govUserExtendedData?: ExtendedGovernmentData
  technologyTypes?: Array<TechnologyTypeResponse>
  userAppNotificationSetting: UserNotificationSetting
  userEmailNotificationSetting: UserNotificationSetting
  isActive?: boolean
}

export interface NotificationResponse {
  id: number
  notificationType: string
  read: boolean
  readAt?: string
  message: string
  createdAt: string
  updatedAt: string

  // present if notificationType === NewMessageNotification
  chatThreadId?: number
}

export interface NewMessageNotificationResponse extends NotificationResponse {
  // present if notificationType === NewMessageNotification
  chatThreadId: number
}

export const isNewMessageNotification = (
  o: NotificationResponse,
): o is NewMessageNotificationResponse => {
  return o.notificationType === 'NewMessageNotification'
}

export const hasGovRole = (user: UserData | GovUserData) => {
  return user.userRoles.includes('gov')
}

export const hasIgniteRole = (user: UserData | GovUserData) => {
  return user.userRoles.includes('ignite')
}

export const isUserData = (o: any): o is UserData => {
  if (o === undefined) return false
  return 'id' in o && !!o['id'] && 'email' in o && !!o['email']
}

export const isAccountData = (o: any): o is AccountData => {
  if (o === undefined) return false
  return 'uniqueEntityId' in o && !!o['uniqueEntityId'] && 'name' in o && !!o['name']
}

export type ExtendedGovernmentData = {
  govUserOrganization: GovUserOrganizationResponse
  govUserProgramOffice?: GovUserProgramOfficeResponse
  visionUserId: number
  profileDetail: string
  interactionDetail?: string
}

export type UserNotificationSetting = {
  id: number
  newChatMessage?: boolean
  userId: number
}

export interface GovUserData extends UserData {
  govUserExtendedData: ExtendedGovernmentData
  technologyTypes: Array<TechnologyTypeResponse>
  govUserSetting: GovUserAccountSettings
  searchMetadata?: GenericSearchMetadata
  chatThreadIdsWithCurrentUserAccount?: Array<number>
}

export interface InvitedUserData {
  userInviteId: number
  accountId: number
  firstName: string
  lastName: string
  email: string
  inviteTokenExpiry: string
}

export type ClaimRequestForm = {
  email: string
  firstName: string
  lastName: string
  uniqueEntityId: string
  recaptchaToken: string
}

export type GovUserAccountSettings = {
  id: number
  userId: number
  allowMessageRequests?: boolean
}

export type ResendClaimFormData = {
  claimToken: string
}

export type ResendClaimFormDataAlternative = {
  email: string
  uniqueEntityId: string
}
export const isResendClaimFormDataAlternative = (
  o: ResendClaimFormData | ResendClaimFormDataAlternative,
): o is ResendClaimFormDataAlternative => {
  return 'email' in o && !!o.email && 'uniqueEntityId' in o && !!o.uniqueEntityId
}

export type InviteUserSignupForm = {
  user: {
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    title: string
    cellPhone: string
    workPhone?: string
    workPhoneExt?: string
    timezone: string
    headshot: File | string | undefined
    socialMedia?: Array<SocialMediaUserFormData>
  }
  recaptchaToken: string
  inviteToken: string
}

export type ClaimRequestSignUpFormData = Omit<InviteUserSignupForm, 'inviteToken'> & {
  claimToken: string
}

export type GovUserSignUpFormData = {
  user: {
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    title: string
    timezone: string
    email: string
    govUserOrganizationId: number
    govUserProgramOfficeId?: number
    headshot: File | string | undefined
    technologyTypes?: Array<TechnologyTypeResponse & SelectableItem>
    encryptedVisionUserData?: string
    profileDetail?: string
    interactionDetail?: string
  }
  recaptchaToken: string
}

export type UpdateUserForm = {
  firstName: string
  lastName: string
  title: string
  cellPhone?: string
  workPhone?: string
  workPhoneExt?: string
  timezone: string
  headshot?: File
  socialMedia?: Array<{
    id?: number
    socialMediaTypeId: number | string
    identifier: string
    destroy?: boolean
  }>
  govUserExtendedDataAttributes?: {
    govUserOrganizationId: number
    govUserProgramOfficeId?: number
    profileDetail?: string
    interactionDetail?: string
  }
  technologyTypes?: Array<TechnologyTypeResponse & SelectableItem>
}

export type UserFormData = SignupLoginData &
  SignupUserData & {
    recaptchaToken: string | null
  }

export type AccountData = {
  id: number
  name: string
  uniqueEntityId: string
  defaultListingId: number | string | undefined
  logo?: FileResponseData
}

export type ChangePasswordData = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export type UpdateAccountForm = {
  name: string
}

export const isUserSubmittable = (data: Partial<UserFormData>): boolean => {
  let errors = [
    validatePassword(data.password),
    validateValueMatch(data.password, data.confirmPassword),
    validateLength(data.firstName, 2),
    validateLength(data.lastName, 2),
    validateLength(data.title, 2),
    validateLength(data.cellPhone, 2),
  ]

  if (errors.some((err) => !!err)) {
    return false
  }

  let presence = [data.timezone, data.recaptchaToken].every((e) => !!e)

  return presence
}

export const claimErrorDictionary: { [key: string]: string } = {
  InvalidClaimToken: 'The claim token provided is invalid',
  ClaimPeriodExpired: 'The provided claim token has expired',
  UnknownError: 'Something went wrong; please try again',
}

export class UserStore extends ServerStore {
  public readonly baseUserURL: string = '/api/v1/users'
  public currentUserData?: UserData = undefined
  public currentAccountData?: AccountData = undefined
  public websocketConsumer?: Consumer = undefined

  constructor(authStore: AuthStore, notificationStore: NotificationStore) {
    super(authStore, notificationStore)
    makeObservable(this, {
      currentUserData: observable,
      setCurrentUserData: action,
      currentAccountData: observable,
      setCurrentAccountData: action,
      websocketConsumer: observable,
      setWebsocketConsumer: action,
    })
  }

  public setCurrentUserData(currentUserData: UserData | undefined) {
    this.currentUserData = currentUserData
  }

  public setCurrentAccountData(currentAccountData: AccountData | undefined) {
    this.currentAccountData = currentAccountData
  }

  public retrieveCurrentUserData = (eager?: string): Promise<GovUserData | UserData> => {
    return this.server()
      .get<GovUserData | UserData>('/api/v1/current_user', { params: { eager } })
      .then(({ data }) => data)
  }

  public retrieveCurrentGovUserData = (eager?: string): Promise<GovUserData> => {
    return this.server()
      .get<GovUserData>('/api/v1/current_user', { params: { eager } })
      .then(({ data }) => data)
  }

  public retrieveCurrentAccountData = (): Promise<AccountData> => {
    return this.server()
      .get<AccountData>('/api/v1/current_user/account')
      .then(({ data }) => data)
  }

  public retrieveGovUserById = (id: string, eager?: string): Promise<GovUserData> => {
    return this.server()
      .get<GovUserData>(`/api/v1/gov/users/${id}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public updateUserSettings = (params: {
    allowMessageRequests?: boolean
    allowInAppNotifications?: boolean
    allowByEmailNotifications?: boolean
  }): Promise<GovUserData> => {
    let payload = {
      govUserSettingAttributes: {
        allowMessageRequests: params.allowMessageRequests,
      },
      userAppNotificationSettingAttributes: {
        newChatMessage: params.allowInAppNotifications,
      },
      userEmailNotificationSettingAttributes: {
        newChatMessage: params.allowByEmailNotifications,
      },
      eager:
        'technologyTypes,userEmailNotificationSetting,userAppNotificationSetting,govUserSetting',
    }

    return this.server()
      .patch<GovUserData>('/api/v1/users', payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => data)
  }

  public getGovUsers(
    params: GovUserPaginatedSearchableParams,
  ): Promise<ServerPageData<GovUserData>> {
    return this.server()
      .get(`/api/v1/gov/users`, {
        params: {
          ...params,
          eager:
            'technologyTypes,userEmailNotificationSetting,userAppNotificationSetting,chatThreadIdsWithCurrentUserAccount,isActive,lastLogin',
        },
      })
      .then(({ data }) => data)
  }

  public signInUser = (email: string, password: string): Promise<any> => {
    const payload = { user: { email, password } }
    return this.server()
      .post(`${this.baseUserURL}/sign_in`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-User-Email': email,
        },
      })
      .then((res) => {
        this.authStore.setJWT(res.headers.authorization)
        return res.data
      })
  }

  public signOutUser = () => {
    // Intentionally abandoning this promise to the server.
    // The FE doesn't need to care if the serverside invalidation succeeded or not
    this.server().delete(`${this.baseUserURL}/sign_out`)
    this.setWebsocketConsumer(undefined)
    this.authStore.setJWT(null)
    this.setCurrentUserData(undefined)
    this.setCurrentAccountData(undefined)
  }

  public submitClaimRequest = (form: ClaimRequestForm): Promise<ClaimRequestData> => {
    return this.server()
      .post<ClaimRequestData>('/api/v1/claim_requests', form, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => data)
  }

  public resendClaim = (
    form: ResendClaimFormData | ResendClaimFormDataAlternative,
  ): Promise<ClaimRequestData> => {
    return this.server()
      .patch<ClaimRequestData>('/api/v1/claim_requests/resend', form, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => data)
  }

  public inviteSignUp = (
    form: InviteUserSignupForm,
  ): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('recaptchaToken', form.recaptchaToken)
    formData.append('firstName', form.user.firstName)
    formData.append('lastName', form.user.lastName)
    formData.append('title', form.user.title)
    formData.append('cellPhone', form.user.cellPhone)
    formData.append('timezone', form.user.timezone)
    form.user.headshot && formData.append('headshot', form.user.headshot)
    form.user.workPhone && formData.append('workPhone', form.user.workPhone)
    form.user.workPhoneExt && formData.append('workPhoneExt', form.user.workPhoneExt)
    formData.append('password', form.user.password)
    formData.append('passwordConfirmation', form.user.confirmPassword)
    formData.append('inviteToken', form.inviteToken)
    form.user.socialMedia &&
      form.user.socialMedia.forEach((sm, i) => {
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          sm.socialMediaTypeId.toString(),
        )
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][identifier]`,
          sm.identifier.toString(),
        )
      })

    return this.server()
      .post<UserData>('/api/v1/user_invites/accept_invite', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public claimSignUp = async (
    form: ClaimRequestSignUpFormData,
  ): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('recaptchaToken', form.recaptchaToken)
    formData.append('firstName', form.user.firstName)
    formData.append('lastName', form.user.lastName)
    formData.append('title', form.user.title)
    formData.append('cellPhone', form.user.cellPhone)
    formData.append('timezone', form.user.timezone)
    form.user.headshot && formData.append('headshot', form.user.headshot)
    form.user.workPhone && formData.append('workPhone', form.user.workPhone)
    form.user.workPhoneExt && formData.append('workPhoneExt', form.user.workPhoneExt)
    formData.append('password', form.user.password)
    formData.append('passwordConfirmation', form.user.confirmPassword)
    formData.append('claimToken', form.claimToken)
    form.user.socialMedia &&
      form.user.socialMedia.forEach((sm, i) => {
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          sm.socialMediaTypeId.toString(),
        )
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][identifier]`,
          sm.identifier.toString(),
        )
      })

    return this.server()
      .post<UserData>('/api/v1/claim_requests/claim_account', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public govUserSignUp = async (
    form: GovUserSignUpFormData,
  ): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('recaptchaToken', form.recaptchaToken)
    formData.append('firstName', form.user.firstName)
    formData.append('lastName', form.user.lastName)
    formData.append('title', form.user.title)
    formData.append('timezone', form.user.timezone)
    form.user.headshot && formData.append('headshot', form.user.headshot)
    formData.append('password', form.user.password)
    formData.append('passwordConfirmation', form.user.confirmPassword)
    formData.append('email', form.user.email)
    form.user.encryptedVisionUserData &&
      formData.append('encryptedVisionUserData', form.user.encryptedVisionUserData)
    formData.append('govUserOrganizationId', form.user.govUserOrganizationId.toString())
    form.user.govUserProgramOfficeId &&
      formData.append('govUserProgramOfficeId', form.user.govUserProgramOfficeId.toString())

    form.user.technologyTypes &&
      form.user.technologyTypes.forEach((tt, i) => {
        formData.append(`technologyTypes[][id]`, tt.id.toString())
      })

    form.user.profileDetail && formData.append('profileDetail', form.user.profileDetail.toString())
    form.user.interactionDetail &&
      formData.append('interactionDetail', form.user.interactionDetail.toString())

    let redirectTo = getFullSessionUrl()
    redirectTo && formData.append('redirectTo', encodeURIComponent(redirectTo))

    return this.server()
      .post<UserData>('/api/v1/gov_users/signup', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public updateUser = (form: UpdateUserForm): Promise<UserData & { authorization: string }> => {
    const formData = new FormData()
    formData.append('firstName', form.firstName)
    formData.append('lastName', form.lastName)
    formData.append('title', form.title)
    form.cellPhone && formData.append('cellPhone', form.cellPhone)
    formData.append('timezone', form.timezone)
    form.headshot && formData.append('headshot', form.headshot)
    form.workPhone && formData.append('workPhone', form.workPhone)
    form.workPhoneExt && formData.append('workPhoneExt', form.workPhoneExt)
    form.govUserExtendedDataAttributes?.govUserOrganizationId &&
      formData.append(
        `govUserExtendedDataAttributes[govUserOrganizationId]`,
        form.govUserExtendedDataAttributes.govUserOrganizationId.toString(),
      )

    form.govUserExtendedDataAttributes?.govUserProgramOfficeId &&
      formData.append(
        `govUserExtendedDataAttributes[govUserProgramOfficeId]`,
        form.govUserExtendedDataAttributes.govUserProgramOfficeId.toString(),
      )
    form.govUserExtendedDataAttributes?.profileDetail &&
      formData.append(
        `govUserExtendedDataAttributes[profileDetail]`,
        form.govUserExtendedDataAttributes.profileDetail,
      )
    form.govUserExtendedDataAttributes?.interactionDetail &&
      formData.append(
        `govUserExtendedDataAttributes[interactionDetail]`,
        form.govUserExtendedDataAttributes.interactionDetail,
      )
    form.socialMedia &&
      form.socialMedia.forEach((sm, i) => {
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][socialMediaTypeId]`,
          sm.socialMediaTypeId.toString(),
        )
        formData.append(
          `userSocialMediaAccountsAttributes[${i}][identifier]`,
          sm.identifier.toString(),
        )

        sm.id && formData.append(`userSocialMediaAccountsAttributes[${i}][id]`, sm.id.toString())
        sm.destroy &&
          formData.append(
            `userSocialMediaAccountsAttributes[${i}][_destroy]`,
            sm.destroy.toString(),
          )
      })

    form.technologyTypes &&
      form.technologyTypes.forEach((tt, i) => {
        formData.append(`technologyTypes[][id]`, tt.id.toString())
        tt.destroy && formData.append(`technologyTypes[][_destroy]`, tt.destroy.toString())
      })

    return this.server()
      .patch<UserData>('/api/v1/users', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public changePassword = (
    params: ChangePasswordData,
  ): Promise<UserData & { authorization: string }> => {
    return this.server()
      .patch<UserData>(
        '/api/v1/users',
        { user: params },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(({ data, headers: { authorization } }) => ({ ...data, authorization }))
  }

  public resendConfirmationEmail(email?: string) {
    const payload: ConfirmationEmailBody = {}
    payload.user = email ? { email } : undefined
    let redirect = getFullSessionUrl()
    payload.redirectTo = redirect ? encodeURIComponent(redirect) : undefined

    return this.server()
      .post('/api/v1/users/confirmation', payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => data)
  }

  public confirmUser(token: string) {
    return this.server().get('/api/v1/users/confirmation', {
      params: { confirmation_token: token },
    })
  }

  public checkUserInvite(token: string) {
    return this.server()
      .get('/api/v1/user_invites/check_invite', {
        params: { invite_token: token },
      })
      .then(({ data }) => data)
  }

  public checkClaim = async (claimToken: string): Promise<ClaimRequestData> => {
    return this.server()
      .get('/api/v1/claim_requests/check_claim', {
        params: { claimToken },
      })
      .then(({ data }) => data)
  }

  public decryptAndValidateGovUserData = async (q: string): Promise<DecryptedGovUserData> => {
    return this.server()
      .get('/api/v1/gov_users/vision_redirection_data', {
        params: { q },
      })
      .then(({ data }) => data)
  }

  public saveGovUserDataFromVision = async (q: string): Promise<DecryptedGovUserData> => {
    return this.server()
      .post('/api/v1/gov_users/save_vision_data_for_existing_users', { q })
      .then(({ data }) => data)
  }

  public checkIfVisionUserHasAccountAndIsLinked = async (
    q: string,
  ): Promise<{ userExists: boolean; isLinked: boolean }> => {
    return this.server()
      .get('/api/v1/gov_users/user_exists_and_is_linked', {
        params: { q },
      })
      .then(({ data }) => data)
  }

  public updateAccount = (form: UpdateAccountForm): Promise<AccountData> => {
    const formData = new FormData()
    formData.append('name', form.name)
    return this.server()
      .patch<AccountData>('/api/v1/current_user/account', formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
      })
      .then(({ data }) => data)
  }

  public changeEmail = (email: string) => {
    return this.server()
      .patch('/api/v1/users', { user: { email: email } })
      .then(({ data }) => data)
  }

  public requestPasswordReset = (email: string): Promise<any> => {
    return this.server()
      .post<any>('/api/v1/users/password', { email: email })
      .then((res: any) => res)
  }

  public resetPassword = (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string,
  ): Promise<any> => {
    const params = {
      password: password,
      passwordConfirmation: passwordConfirmation,
      resetPasswordToken: resetPasswordToken,
    }
    return this.server()
      .patch<any>('/api/v1/users/password', {
        user: params,
      })
      .then((res: any) => res)
  }

  public cancelEmailChange = (): Promise<UserData> => {
    return this.server()
      .patch('/api/v1/users/confirmation/cancel')
      .then(({ data }) => data)
  }

  public createWebsocketConsumer = () => {
    if (!this.authStore.getJWT()) {
      return
    }

    this.setWebsocketConsumer(Cable.createConsumer(`/cable?jwt=${this.authStore.getJWT()}`))
  }

  public setWebsocketConsumer = (value: Consumer | undefined) => {
    this.websocketConsumer = value
  }

  public countNotifications = (params: { read?: boolean }): Promise<CountResponse> => {
    return this.server()
      .get('/api/v1/current_user/notifications/count', { params })
      .then(({ data }) => data)
  }

  public getNotifications = (
    params: PaginationParams & SortableParams & { read?: boolean },
  ): Promise<ServerPageData<NotificationResponse>> => {
    return this.server()
      .get('/api/v1/current_user/notifications', { params })
      .then(({ data }) => data)
  }

  public markAllNotificationsRead = (): Promise<undefined> => {
    // Is a 204
    return this.server()
      .patch('/api/v1/current_user/notifications/read')
      .then(({ data }) => data)
  }

  public markNotificationRead = (id: number): Promise<undefined> => {
    // Is a 204
    return this.server()
      .patch(`/api/v1/current_user/notifications/${id}/read`)
      .then(({ data }) => data)
  }
}
