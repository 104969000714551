import { useState } from 'react'
import { CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import { UserFormData, isUserSubmittable, claimErrorDictionary } from '../stores/UserStore'
import { SignupLoginForm, SignupUserForm } from '../components/form/fragments'
import ReCAPTCHA from 'react-google-recaptcha'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { SuspensefulButton } from '@mobilizeyourtech/vision-core-react'
import { Link } from 'react-router-dom'
import { leftArrow } from '../components/icons/leftArrow'
import { extractRestfulError } from '../lib/errors/utils'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  applicationStore?: ApplicationStore
}
export const ClaimRequestSignUp = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  'applicationStore',
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      claimToken: withDefault(StringParam, undefined),
      firstName: withDefault(StringParam, undefined),
      lastName: withDefault(StringParam, undefined),
    })
    const [newUserData, setNewUserData] = useState<Partial<UserFormData>>({
      firstName: params.firstName,
      lastName: params.lastName,
      recaptchaToken: null,
    })
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const onSubmit = () => {
      if (!isUserSubmittable(newUserData)) {
        return
      }

      setHasValidated(true)
      setIsSubmitting(true)

      props
        .userStore!.claimSignUp({
          user: {
            password: newUserData.password!,
            confirmPassword: newUserData.confirmPassword!,
            firstName: newUserData.firstName!,
            lastName: newUserData.lastName!,
            title: newUserData.title!,
            cellPhone: newUserData.cellPhone!,
            workPhone: newUserData.workPhone,
            workPhoneExt: newUserData.workPhoneExt,
            timezone:
              typeof newUserData.timezone === 'string'
                ? newUserData.timezone
                : newUserData.timezone!.value,
            headshot: newUserData.headshot,
            socialMedia: newUserData.socialMedia,
          },
          recaptchaToken: newUserData.recaptchaToken!,
          claimToken: params.claimToken!,
        })
        .then(({ authorization }) => {
          props.notificationStore?.setNotificationMessage(
            'Account successfully claimed!',
            'success',
            3000,
          )
          props.authStore!.setJWT(authorization)
          props.authStore!.generateAndSetSalt()
        })
        .catch((errorResponse) => {
          let restfulError = extractRestfulError(errorResponse)
          let errorMessage: string

          if (restfulError?.error && claimErrorDictionary.hasOwnProperty(restfulError.error)) {
            errorMessage = restfulError.error
          } else {
            errorMessage = 'UnknownError'
          }

          props.notificationStore?.setNotificationMessage(
            claimErrorDictionary[errorMessage],
            'danger',
            3000,
          )
        })
        .finally(() => setIsSubmitting(false))
    }
    return (
      <section className="ClaimSignUp">
        <CForm validated={hasValidated} className="needs-validation" {...{ noValidate: true }}>
          <CContainer>
            <CCard>
              <CCardBody>
                <div className="bounded-container d-flex align-items-center">
                  <Link
                    className="nav-back clickable"
                    data-testid="nav-back"
                    aria-label="navigate back to signin"
                    tabIndex={0}
                    to="/"
                  >
                    {leftArrow()}
                  </Link>
                  <h5>Sign Up</h5>
                </div>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your Login</b>
                      </h6>
                      <SignupLoginForm
                        value={newUserData}
                        onChange={(value) => setNewUserData(value)}
                        isEmailHidden={true}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div className="bounded-container">
                      <h6>
                        <b>Your User</b>
                      </h6>
                      <SignupUserForm
                        value={newUserData}
                        onChange={(value) => setNewUserData(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CContainer className="recaptcha-container">
                  <ReCAPTCHA
                    sitekey={props.applicationStore!.siteSettings!['recaptchaSiteKey']}
                    onChange={(token) => setNewUserData({ ...newUserData, recaptchaToken: token })}
                    data-testid="recaptcha-checkbox"
                  />
                </CContainer>
                <CRow className="justify-content-md-center">
                  <CCol sm={2} className="d-flex justify-content-md-center">
                    <SuspensefulButton
                      isSuspended={isSubmitting}
                      onInvoke={onSubmit}
                      buttonText="Sign Up"
                      disabled={!isUserSubmittable(newUserData) || isSubmitting}
                      data-testid="signup-button"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CContainer>
        </CForm>
      </section>
    )
  }),
)
